import logo from './letter.jpg';
import letter1 from './letter1.png'
import titleImage from './title-img.png'
import { useState } from 'react'
import './App.css';

function Home() {
  const [showLetter, setShowLetter] = useState(false)
  const [counter, setCounter] = useState(0)
  const [showLove, setShowLove] = useState(false)

  const openLetter = () => {
    setTimeout(() => {
      setShowLetter(true)
    }, 500)
  }

  const showEggs = () => {
    setCounter(counter+1)
  }

  return (
    <div className="home-page">
      <header className="page-header">
        <p className='title' data-text="Happy Valentine's Day Baby!">Happy Valentine's Day Baby!</p>
        <img src={titleImage} className="title-img" onClick={() => {
          setShowLetter(false)
          setShowLove(false)
          setCounter(0)
        }} />
      </header>
      {
        showLetter ? <img src={logo} className={`letter ${showLetter ? 'letter-show' : ''}`} alt="logo" /> : <>
          <div className={`container ${showLetter ? '' : 'letter-show'}`}>
            <div className='letter-box' onClick={() => openLetter()}>
              <img src={letter1} className="logo" alt="logo" />
              <div className='name-box'>
                <span className='name'>收到一封信件！</span>
              </div>
            </div>
            {
              counter >= 7 && <div className='eggs' onClick={() => { setShowLove(true) }}>{showLove ? '爱你老婆，节日快乐！' : '爱意东升西落，浪漫至死不渝！'}</div>
            }
            <div className="tip" onClick={() => showEggs()}>XUE & HENG</div>
          </div>
        </>
      }
    </div>
  );
}

export default Home;
